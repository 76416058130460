import service from "../service.js";

//查询班组安全工器具试验信息
export const searchStationTest = params => {
    return service({
        method: 'POST',
        url: '/stationTest/searchStationTest',
        params
    })
}

export const addStationToolTest = (data) => {
    return service({
        method: 'POST',
        url: '/stationTest/addStationToolTest',
        data
    })
}

export const updateStationToolTest = (data) => {
    return service({
        method: 'POST',
        url: '/stationTest/updateStationToolTest',
        data
    })
}

export const delStationToolTest = (id) => {
    return service({
        method: 'GET',
        url: '/stationTest/delStationToolTest',
        params: {
            id
        }
    })
}

export const queryStationToolTest = (id) => {
    return service({
        method: 'GET',
        url: '/stationTest/queryStationToolTest',
        params: {
            id
        }
    })
}

export const getShTypes = () =>{
    return service({
        method: 'GET',
        url: '/param/getShTypes'
    })
}





